import axios from "axios";
import { getCookie, setCookie } from "utils/helper";
import request from "utils/request";

import { WS_HOST } from "web.config";

export const getClientIp = async () => {
    let ipv4 = getCookie('feClientIp');

    if (ipv4) {
        window.ipv4 = ipv4

    } else {
        let res = await axios(`https://geolocation-db.com/json/`)
        setCookie('feClientIp', res.data.IPv4)
        window.ipv4 = res.data.IPv4
    }
}

export const getCsrfToken = (requestData) => {
    return request({
        method: "POST",
        requestData,
        url: `${WS_HOST}/token/csrf`,
        is_json_content_type: true,
    });
}