import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { change, clearFields, reduxForm } from 'redux-form';
import { facebookLogin, googleLogin } from 'utils/SocialAuth';
import RenderAuthenticationSlider from 'utils/Form/RenderAuthenticationSlider';
import { SocialLoginButton, Wrapper } from './styled';
import { GMButton } from 'components/Button';
import GMNotify from 'components/Notification';
import { GMTextbox } from 'components/InputFields';
import { verifyEmail } from 'scenes/Auth/scenes/VerifyEmail/actions';
import logo from 'images/logo_new_login.svg';
import validate from './validate';
import facebookIcon from 'images/fb_icon.svg';
import googleIcon from 'images/gg.svg';
import { GMCopyright, GMTermPrivacy } from 'info';
import { useDispatch } from 'react-redux';
import Turnstile, { useTurnstile } from 'react-turnstile'
// import { verifyEmail } from 'scenes/Auth/scenes/VerifyEmail/actions';

import { ReactComponent as ArrowNarrowRightIcon } from 'images/icons/arrow-narrow-right.svg';
import { ReactComponent as CheckIcon } from 'images/icons/check.svg';
import { PRIMARY_500 } from 'components/colors';
import { Spin } from 'antd';
import { REACT_APP_CF_TURNSTILE_SITE_KEY } from 'web.config';

const Form = (props) => {
  const {
    handleSubmit,
    formError,
    successResendEmail,
    loadingVerify,
    resendData,
  } = props;

  const dispatch = useDispatch();
  const turnstile = useTurnstile();
  let email = formError.msg
    ? formError.msg.slice(26, formError.msg.indexOf('}'))
    : '';

  useEffect(() => {
    dispatch(clearFields('formLogin'));
    setTimeout(() => {
      const pwInput = document.getElementsByName('password')?.[0];
      if (pwInput) {
        pwInput.value = '';
      }
    }, 0);
  }, []);

  useEffect(
    () => {
      if (formError.msg) {
        turnstile.reset();
      }
    }, [formError.msg]
  )

  const socialLogin = (loginType) => {
    if (loginType === 'google') {
      googleLogin((error, result) => {
        if (error) {
        } else if (result.accessToken) {
          props.onSubmit({ accessToken: result.accessToken, loginType });
        }
      });
    } else if (loginType === 'facebook') {
      facebookLogin((error, result) => {
        let accessToken = '';
        if (error) {
          accessToken = error?.credential?.accessToken;
        } else {
          accessToken = result.accessToken;
        }
        if (accessToken) {
          props.onSubmit({ accessToken: accessToken, loginType });
        }
      });
    }
  };
  const resendEmail = (values) => {
    dispatch(verifyEmail({ email: values }));
  };

  return (
    <Wrapper className="container-fluid">
      <div className="font-login row">
        <div className="col-lg-6 col-md-12 col-xs-12 form-section">
          <div className="left-form">
            <form onSubmit={handleSubmit}>
              <div className="form-login">
                <div className="text-center">
                  <img
                    className="img-fluid"
                    src={logo}
                    style={{ width: '242px', height: '48px' }}
                    alt="logo"
                  />
                </div>
                <div className="text-center font-login-title">Welcome back</div>
                <div className="text-center font-login-desc">
                  Please enter your credentials to log in.
                </div>
                <div>
                  {formError?.msg &&
                    !formError?.resend_verification_email && (
                      <div className="noti-margin">
                        <GMNotify
                          type="warning"
                          title={
                            <div className="title">Invalid credentials.</div>
                          }
                          desc={
                            <>
                              <div className="desc">{formError?.clientMsg}</div>
                              {formError.attemptMsg && <div className="desc"><i>{formError.attemptMsg}</i></div>}
                            </>
                          }
                        />
                      </div>
                    )}
                  {formError?.clientMsg &&
                    formError?.resend_verification_email === true && (
                      <div className="noti-margin">
                        <GMNotify
                          type="infor"
                          title={
                            <div className="title">Invalid credentials.</div>
                          }
                          desc={
                            <div style={{ cursor: 'pointer' }}>
                              <div className="desc">
                                Your email address needs to be verified. Please
                                click on the link in the email to verify your
                                email address. If you have not received the
                                verification email, please check your 'Spam' or
                                'Bulk Email' folder.
                              </div>
                              <div className='d-flex justify-content-end'>
                                {' '}
                                {successResendEmail ? (
                                  <div className="email">
                                    Email sent
                                    <CheckIcon
                                      stroke={PRIMARY_500}
                                      className=" ml-2"
                                    />
                                  </div>
                                ) : (
                                  <Spin
                                    spinning={loadingVerify}
                                    className="email"
                                    onClick={() =>
                                      !loadingVerify &&
                                      resendEmail(resendData?.email)
                                    }
                                    style={{ cursor: 'pointer' }}
                                  >
                                    Resend verification email
                                    <ArrowNarrowRightIcon
                                      stroke={PRIMARY_500}
                                      className=" ml-2"
                                    />
                                  </Spin>
                                )}
                              </div>
                            </div>
                          }
                        />
                      </div>
                    )}
                </div>
                {/* {formError.code === 'not_activated_user' ||
                formError.code === 'invalid_param' ? (
                  <div className="noti-margin">
                    <GMNotify
                      type="warning"
                      title={<div className="title">Invalid credentials.</div>}
                      desc={
                        <div className="desc">
                          Please check your email or password again.
                        </div>
                      }
                    />
                  </div>
                ) : formError.code === 'blocked_user' ? (
                  <div className="noti-margin">
                    <GMNotify
                      type="warning"
                      title={<div className="title">Invalid credentials.</div>}
                      desc={
                        <div className="desc">
                          Your account has been locked. Please contact our
                          customer service for more information.
                        </div>
                      }
                    />
                  </div>
                ) : formError.code === 'not_activated_user' &&
                  formError.resend_verification_email === true ? (
                  <div className="noti-margin">
                    <GMNotify
                      type="infor"
                      title={<div className="title">Invalid credentials.</div>}
                      desc={
                        <div style={{ cursor: 'pointer' }}>
                          <div className="desc">
                            Your email address needs to be verified. Please
                            click on the link in the email to verify your email
                            address. If you have not received the verification
                            email, please check your 'Spam' or 'Bulk Email'
                            folder.
                          </div>
                          {successResendEmail ? (
                            <div className="email">
                              Email sent
                              <CheckIcon
                                stroke={PRIMARY_500}
                                className=" ml-2"
                              />
                            </div>
                          ) : (
                            <Spin
                              spinning={loadingVerify}
                              className="email"
                              onClick={() =>
                                !loadingVerify && resendEmail(email)
                              }
                              style={{ cursor: 'pointer' }}
                            >
                              Resend verification email
                              <ArrowNarrowRightIcon
                                stroke={PRIMARY_500}
                                className=" ml-2"
                              />
                            </Spin>
                          )}
                        </div>
                      }
                    />
                  </div>
                ) : (
                  ''
                )} */}
              </div>
              <div className="size-input">
                <div className="form-group">
                  <label className="name-field">Email</label>
                  <GMTextbox
                    name="email"
                    placeholder="Email"
                    className="input-field"
                    showError
                    maxLength={256}
                  />
                </div>
                <div className="form-group">
                  <label className="name-field ">Password</label>
                  <GMTextbox
                    name="password"
                    placeholder="Password"
                    type="password"
                    className="input-field"
                    showError
                    maxLength={50}
                  />
                </div>
                <div>
                  <Link to="/auth/forgot" className="forgot-password">
                    Forgot password
                  </Link>
                </div>
                <div className='mt-1rem'>
                  {/* Cloudflare Turnstile captcha */}
                  <GMTextbox name="captcha-token" className="hidden-popup-menu"/>
                  <Turnstile
                    sitekey={REACT_APP_CF_TURNSTILE_SITE_KEY}
                    refreshExpired='auto'
                    theme='light'
                    responseField={false}
                    onVerify={(token) => {
                      dispatch(change('formLogin', 'captcha-token', token));
                    }}
                  />
                </div>
                <div>
                  <GMButton type="submit" size="lg" className="login-button">
                    Log in
                  </GMButton>
                </div>
                <div className="devider-login text-muted"></div>
                <div className="social-login-button">
                  <div>
                    <button
                      onClick={() => socialLogin('google')}
                      type="button"
                      className="button-Social"
                    >
                      <img
                        className="social-login-icon"
                        src={googleIcon}
                        alt="google icon"
                      />
                    </button>
                  </div>
                  <div>
                    <SocialLoginButton
                      onClick={() => socialLogin('facebook')}
                      type="button"
                      className="button-Social"
                      disabled
                    >
                      <img
                        className="social-login-icon"
                        src={facebookIcon}
                        alt="facebook icon"
                      />
                    </SocialLoginButton>
                  </div>
                </div>

                <div className="text-center footer-login">
                  <Link className="sign-up" to="/auth/register">
                    Create new account
                  </Link>
                </div>
              </div>
            </form>
            {GMTermPrivacy}
            {GMCopyright}
          </div>
        </div>
        <div className="col-md-6 right-authentication-section">
          <div className="authentication-slider img-bg min-vh-100 d-flex align-items-center justify-content-center">
            <RenderAuthenticationSlider />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

Form.propTypes = {};

export default reduxForm({
  form: 'formLogin',
  enableReinitialize: true,
  validate,
})(Form);
