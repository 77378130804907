import { GlowingSVG } from "components/SVG";
import React, {useState} from "react";
import { connect, useDispatch } from "react-redux";
import { reduxForm, change } from "redux-form";
import { ReactComponent as KeyIcon } from 'images/icons/key-01.svg';
import { PRIMARY_100, PRIMARY_50, PRIMARY_700 } from "components/colors";
import { ReactComponent as AlertCircle } from 'images/icons/alert-circle.svg';
import { GMButton } from "components/Button";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { clearMessage } from "../actions";
import {REACT_APP_CF_TURNSTILE_SITE_KEY} from 'web.config';
import {GMTextbox} from "../../../../../components/InputFields";
import ResetPasswordSuccess from "./ResetPasswordSuccess";
import Turnstile from "react-turnstile";


const FormResetPassword = ({ handleSubmit }) => {
  const serverErrors = useSelector(state => state.user.reset_password.serverErrorInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, []);

  const errorMessage = serverErrors && serverErrors.length > 0 ? (
    serverErrors.map(error => {
      switch (error?.code) {
        case 'invalid_param':
          return (
            <li>
              <AlertCircle />
              <span className="error">Incorrect</span>
            </li>
          );
        default:
          return (
              <li>
                  <AlertCircle />
                  <span className="error">{error?.clientMsg}</span>
              </li>
          );
      }
    })
  ) : null;

  const serverSuccess = useSelector(state => state.user.reset_password.serverSuccessInfo);
  const cusEmail = useSelector((s) => s?.auth?.info?.profile.email || "");

  const form = (
    <form onSubmit={handleSubmit}>
      <div className="text-center logo">
        <GlowingSVG
          backgroundColor={PRIMARY_100}
          borderColor={PRIMARY_50}
          svgStrokeColor={PRIMARY_700}
        >
          <KeyIcon />
        </GlowingSVG>
      </div>
      <h3 className="text-center title">Reset password instruction</h3>
        <br/>
        <p className="title"><strong>Step 1:</strong> Confirm your're not a robot by completing the verification process</p>
        <p className="title"><strong>Step 2:</strong> Click <strong>"Confirm & Continue"</strong> to process</p>
        <p className="title"><strong>Step 3:</strong> You'll receive an email with a link to reset password. Follow the link, enter your new password.</p>
        <p className="title">Once you confirmed the new password, your information will be updated successfully.</p>
      {errorMessage ? (
        <ul className="error-list">{errorMessage}</ul>
      ) : null}
        <div className="form-group text-center">
            <GMTextbox name="captcha-token" className="hidden-popup-menu"/>
            <Turnstile
                sitekey={REACT_APP_CF_TURNSTILE_SITE_KEY}
                refreshExpired='auto'
                responseField={false}
                onVerify={(token) => {
                    dispatch(change('FormResetPassword', 'captcha-token', token));
                }}
            />
        </div>
        <div className="form-group">
            <GMTextbox
                name="email"
                value={cusEmail}
                type="hidden"
            />
        </div>
      <div className="form-group">
        <GMButton type="submit" className="btn btn-primary cta-changepss">
          Confirm & Continue
        </GMButton>
      </div>
    </form>
  );

  return (
      serverSuccess && serverSuccess.code == "success" ? <ResetPasswordSuccess /> : form
  );
};

const mapStateToProps = (state) => ({
  form_values: state.form.FormResetPassword,
  initialValues: {
    email: state.auth.info?.profile?.email,
  } 

});

const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: function (field, value) {
    dispatch(change("FormResetPassword", field, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "FormResetPassword",
    enableReinitialize: true,
  })(FormResetPassword)
);
