import axios from "utils/request";
import { getCsrfToken } from "./common";

const prefix = "/auth";

export const login = async (data) => {
  let endpoint = `${prefix}/login`
  //Get CSRF Token
  let csrf = await getCsrfToken({
    'ip': window.ipv4,
    'from': endpoint
  })
  
  return !csrf.code ? csrf : axios({
    headers: {
      'FE-CSRF-VALUE': csrf.data.value,
      'FE-EMAIL': data.email
    },
    method: "POST",
    data,
    url: endpoint,
    is_json_content_type: true,
  });
};

export const logout = () => {
  return axios({
    method: "GET",
    url: `${prefix}/logout`,
  });
};

export const register = async (data) => {
    let endpoint = `/accounts/register`
    // Get CSRF Token
    let csrf = await getCsrfToken({
      'ip': window.ipv4,
      'from': endpoint
    })

    return !csrf.code ? csrf : axios({
      headers: {
        'FE-CSRF-VALUE': csrf.data.value
      },
      method: "POST",
      data,
      url: endpoint,
      is_json_content_type: true,
    });
};

export const forgetPassword = async (data) => {
    let endpoint = `/accounts/forget-password`
    // Get CSRF Token
    let csrf = await getCsrfToken({
      'ip': window.ipv4,
      'from': endpoint
    })

    return !csrf.code ? csrf : axios({
      headers: {
        'FE-CSRF-VALUE': csrf.data.value
      },
      method: "POST",
      data,
      url: endpoint,
      is_json_content_type: true,
    });
};

export const resetPassword = (data) => {
  return axios({
    method: "POST",
    data,
    url: `/accounts/reset-password`,
    is_json_content_type: true,
  });
};

export const verifyTokenResetPassword = (data) => {
  return axios({
    method: "POST",
    data,
    url: `/accounts/verify-reset-password-token`,
    is_json_content_type: true,
  });
};

export const verifyEmail = (data) => {
  return axios({
    method: "POST",
    data,
    url: `/accounts/resend-verification-email`,
    is_json_content_type: true
  })
}

export const verifyTokenEmail = (data) => {
  return axios({
    method: "POST",
    data,
    url: `/accounts/verification-email`,
    is_json_content_type: true
  })
}

export const createAccountStaff = (data) => {
  return axios({
    method: "POST",
    data,
    url: `/staff/store`,
    is_json_content_type: true,
  });
};

export const verifyStaffToken = (data) => {
  return axios({
    method: "POST",
    data,
    url: `/staff/verification-email`,
    is_json_content_type: true,
  });
};

