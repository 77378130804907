import React, { memo, useState, useEffect, useRef } from "react";
import { AdsOverlay, AdsWrapper } from "./styled";
import Slick from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";
import { ReactComponent as RightIcon } from "images/icons/chevron-right.svg";
import { ReactComponent as LeftIcon } from "images/icons/chevron-left.svg";
import moment from "moment";
import { useLocation } from 'react-router-dom';

import { isElementHidden } from "./utils";

import ImgLoader from "images/Promotion/loader.svg";
import MImgLoader from "images/Promotion/mloader.svg";

import PC_ResetPass from "images/Promotion/PC_ResetPass.png";
import MB_ResetPass from "images/Promotion/MB_ResetPass.png";

const CustomNavigate = ({ children, className, customClassName, ...p }) => {
  return (
      <div className={customClassName} {...p}>
        <div>{children}</div>
      </div>
  );
};

const settings = {
  dots: false,
  infinite: !!1,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: "0",
  arrows: !!1,
  autoplay: !!1,
  useTransform: !!1,
  autoplaySpeed: 8000,
  dots: !!1,
  nextArrow: (
      <CustomNavigate customClassName="gm-nav-next">
        <RightIcon />
      </CustomNavigate>
  ),
  prevArrow: (
      <CustomNavigate customClassName="gm-nav-prev">
        <LeftIcon />
      </CustomNavigate>
  ),
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        arrows: !1
        // dots: !1
      }
    }
  ]
};
const slides = [
  {
    id: 1704271633731,
    img: PC_ResetPass,
    mobileImg: MB_ResetPass,
    link: '/reset-password',
    aspect_ratio: "unset",
  }
];

export let GMADS_STATUS = "GM_RESET_PASS__STATUS__";
const now = moment();
let postfix = "";
slides.forEach((banner, index) => {
  const from = moment(banner.from, "DD/MM/YYYY hh:mm:ss Z");
  const to = moment(banner.to, "DD/MM/YYYY hh:mm:ss Z");
  if (banner.from && from.diff(now, "seconds") > 0) {
    slides[index] = null;
    return;
  }
  if (banner.to && to.diff(now, "seconds") < 0) {
    slides[index] = null;
    return;
  }
  postfix += banner?.id;
});
GMADS_STATUS += postfix;

if (slides.length <= 1) {
  settings.autoplay = !1;
}

export default memo(() => {
  const userInfo = useSelector((s) => s?.auth?.info?.profile || {});
  const [currentSlide, setCurrentSlide] = useState(0);
  const adsRef = useRef();
  useEffect(() => {
    if (!adsRef.current) {
      return;
    }
    const allSlideVids = [...adsRef.current?.getElementsByTagName("video")];
    allSlideVids.forEach((vid) => {
      // eslint-disable-next-line no-unused-expressions
      vid?.pause();
      vid.currentTime = 0;
    });
    const vids = [
      ...adsRef.current
          .getElementsByClassName("slick-current")?.[0]
          ?.getElementsByTagName("video")
    ];
    if (vids.length <= 0) {
      return;
    }
    vids.forEach((vid) => {
      if (!isElementHidden(vid)) {
        // eslint-disable-next-line no-unused-expressions
        vid?.play();
      }
    });
  }, [currentSlide]);
  if (
      slides.length < 0 ||
      slides.length === slides.filter((s) => s === null).length
  ) {
    return null;
  }
  const loader = (
      <>
        <img src={ImgLoader} className="loader" />
        <img src={MImgLoader} className="loader mobile" />
      </>
  );
  const location = useLocation();
  if (userInfo.cusResetPassStatus == 1 && location.pathname != "/reset-password") {
    return (
        <>
          <AdsOverlay/>
          <AdsWrapper ref={adsRef}>
            <div className="inner-wrapper">
              <Slick {...settings} afterChange={(c) => setCurrentSlide(c)}>
                {slides.map((banner, k) => {
                  if (!banner) {
                    return null;
                  }
                  if (banner.video) {
                    return (
                        <div className="vid-banner skeleton" key={"gm-ads__" + k}>
                          {loader}
                          <video preload="yes" className="ds" loop playsInline muted>
                            <source src={banner.video} type="video/mp4"/>
                          </video>
                          <video preload="yes" className="mb" loop playsInline muted>
                            <source src={banner.mobileVideo} type="video/mp4"/>
                          </video>
                          <a
                              href={banner.link}
                              className="link-overlay"
                              target="_self"
                          >
                            &nbsp;
                          </a>
                        </div>
                    );
                  }
                  return (
                      <div style={{textAlign: "center"}} className="skeleton" key={"gm-ads__" + k}>
                        <a href={banner.link} target="_self">
                          <img src={banner.img} className="ds" style={{
                            marginLeft: banner.marginleft,
                            maxWidth: banner.width,
                            aspectRatio: banner.aspect_ratio,
                            display: banner.display
                          }}/>
                          <img src={banner.mobileImg} className="mb"/>
                        </a>
                      </div>
                  );
                })}
              </Slick>
            </div>
          </AdsWrapper>
        </>
    );
  } else {
    return (<></>);
  }
});
