/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { change, reduxForm } from 'redux-form';
import { facebookLogin, googleLogin } from 'utils/SocialAuth';
import validate from './validate';
import { ReactComponent as HelpIcon } from 'images/icons/help-circle.svg';
import RenderAuthenticationSlider from 'utils/Form/RenderAuthenticationSlider';
import { GMCopyright } from 'info';
import { GMTextbox } from 'components/InputFields';
import PasswordConditional from 'components/PasswordConditional';
import { GMButton } from 'components/Button';
import { ReactComponent as AlertCircle } from 'images/icons/alert-circle.svg';
import { ReactComponent as GMLogo } from 'images/logo_new_login.svg';
import { login } from '../../Login/actions';
import { resetMessage } from '../actions';
import Success from './Success';
import Turnstile, { useTurnstile } from 'react-turnstile';
import { REACT_APP_CF_TURNSTILE_SITE_KEY } from 'web.config';

const Register = (props) => {
  const { handleSubmit } = props;
  const dispatch = useDispatch();
  const turnstile = useTurnstile();
  const serverErrors = useSelector((state) => state.auth.register.serverErrorMessage);
  const serverSuccess = useSelector((state) => state.auth.register.serverSuccessMessage);
  useEffect(() => {
    dispatch(resetMessage());
  }, []);
  useEffect(
    () => {
      if (serverErrors) {
        turnstile.reset();
      }
    }, [serverErrors]
  )
  const errorMessage = serverErrors ? (
    <li>
      <AlertCircle />
      <span className="error">{serverErrors}</span>
    </li>
  ) : null;

  const registerContent = (
    <>
      <div>
        <h3 className="title">
          Create an account
        </h3>
        <p className="message">Take a journey to print-on-demand everything you need</p>
      </div>
      <div>
        {errorMessage ? (
          <ul className="error-list">{errorMessage}</ul>
        ) : null}
        <div className="form-group">
          <GMTextbox
            name="firstName"
            placeholder="First name"
            label="First name"
            maxLength="256"
            showError
          />
        </div>
        <div className="form-group">
          <GMTextbox
            name="lastName"
            placeholder="Last name"
            label="Last name"
            maxLength="256"
            showError
          />
        </div>
        <div className="form-group">
          <GMTextbox
            name="email"
            placeholder="Email"
            label="Email"
            showError
          />
        </div>
        <div className="form-group">
          <GMTextbox
            name="password"
            placeholder="Password"
            maxLength="50"
            label={(
              <>
                Password
                <Tooltip
                  title={(
                    <PasswordConditional />
                  )}
                  placement="topLeft"
                >
                  <HelpIcon className="help-tooltip" />
                </Tooltip>
              </>
            )}
            type="password"
            message="Use 8 or more characters with a mix of letters, numbers & symbols and does not contain email address"
            showError
          />
        </div>
        <div className="form-group">
          <GMTextbox
            name="confirmPassword"
            placeholder="Confirm password"
            label="Confirm password"
            maxLength="50"
            type="password"
            showError
          />
        </div>
        <div className="form-group term-condition">
          By continuing, you agree to Gearment's
          {' '}<a
            target="_blank"
            href="https://gearment.com/p/terms-of-services/"
            rel="noopener noreferrer"
          >
            Term Of Services
          </a>{' '}
          and acknowledge you've read our
          {' '}<a
            href="https://gearment.com/p/privacy-policy/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy Policies
          </a>.
        </div>
        <div className='mt-1rem mb-2'>
          {/* Cloudflare Turnstile captcha */}
          <GMTextbox name="captcha-token" className="hidden-popup-menu"/>
          <Turnstile
            sitekey={REACT_APP_CF_TURNSTILE_SITE_KEY}
            refreshExpired='auto'
            theme='light'
            responseField={false}
            onVerify={(token) => {
              dispatch(change('formRegister', 'captcha-token', token));
            }}
          />
        </div>
        <div className="mt-2 login-form-button">
          <GMButton
            type="submit"
            className="btn btn-primary button-Login w-100 text-uppercase font-weight-bold"
          >
            Create new account
          </GMButton>
        </div>
        <div className="text-center  mt-2 ">
          <span className="text-muted">
            Already have an account?{' '}
          </span>
          <Link className="font-weight-bold" to="/auth/login">
            Log in
          </Link>
        </div>
      </div>
    </>
  );
  return (
    <div className="container-fluid">
      <div className="row align-items-stretch justify-content-center">
        <div className="col-lg-6 col-md-12 col-xs-12 left-form">
          <div className="signup-form m-auto min-vh-100 d-flex align-items-center px-0  pb-8">
            <form onSubmit={handleSubmit} className="w-100">
              <div className="mb-3">
                <div className="text-center">
                  <GMLogo className="img-fluid logo" />
                </div>
                {serverSuccess?.code !== 'success' ? registerContent : (
                  <Success emailAddress={serverSuccess?.data?.customer?.email || serverSuccess?.email} />
                )}
              </div>
            </form>
          </div>
          {GMCopyright}
        </div>
        <div className="col-md-6 right-authentication-section">
          <div className="authentication-slider min-vh-100 d-flex align-items-center justify-content-center">
            <RenderAuthenticationSlider />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(connect()(
  reduxForm({
    form: 'formRegister',
    enableReinitialize: true,
    validate,
  })(Register)
));
